<template>
  <div class="page-account">

    <!-- header -->
    <div class="py-4 gradient-site">
      <div class="container-fluid px-5">
        <h1 class="text-4xl font-bold mb-2 text-white">User</h1>
        <h5 class="text-2xl mb-8 text-light">Your Details &amp; Settings</h5>
      </div>
    </div>
  
    <!-- loading overlay -->
    <b-overlay :show="userBase_IsLoading" rounded="sm" class="h-100">
  
      <div class="container py-4">
        <div class="row gutters-sm">

          <!-- nav -->
          <div class="col-md-4 d-none d-md-block">
            <b-card>
              <b-card-text>
                <b-nav pills vertical>
                  <b-nav-item :to="{name: 'UserProfile'}" class="mb-2">
                    <b-icon icon="trash"></b-icon> Profile Information
                  </b-nav-item>
                  <b-nav-item disabled :to="{name: 'Dashboard'}" class="mb-2">
                    <b-icon icon="trash"></b-icon> Account Settings
                  </b-nav-item>
                  <!--
                  <b-nav-item :to="{name: 'Dashboard'}" class="mb-2">
                    <b-icon icon="trash"></b-icon> Security
                  </b-nav-item>
                  -->
                  <b-nav-item :to="{name: 'UserSettings'}" class="mb-2">
                    <b-icon icon="trash"></b-icon> Notification (Click me)
                  </b-nav-item>
                  <b-nav-item :to="{name: 'UserAdminMessages'}" class="mb-2">
                    <b-icon icon="trash"></b-icon> System Messages
                  </b-nav-item>
                  <b-nav-item disabled class="mb-2">
                    <b-icon icon="trash"></b-icon> Billing
                  </b-nav-item>
                </b-nav>
              </b-card-text>
            </b-card>
          </div>

          <!-- sub route - testing a way -->
          <div class="col-md-8">
            <router-view />
          </div>

        </div>
      </div>

    </b-overlay>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'User',

  data() {
    return {
      loading: true,
      saving: false,

      user: null,
    }
  },
  
  computed: {
    ...mapGetters('user', [
      'userBase_IsLoading',
      'userBase_IsSaving',
    ]),
    user_id() {
      return this.$route.params.id;
    },
    subroute() {
      return this.$router;
    }
  },

  created() {
    // Set Sidebar Active Part
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'settings'});
  },

  watch: {
    // watch user ID (useful for admin switches), and also execute this on immediate at created
    user_id: {
      handler: 'fetchData',
      immediate: true,
    }
  },

  methods: {
    ...mapActions('user', [
      'setUserLoadingState',
      'setUserSavingState',
    ]),

    // Placeholder code
    async fetchData() {
      console.log("Update!");
      this.setUserLoadingState(true);
      setTimeout(() => { // Fake a data fetcher... only for overlay logic
        this.setUserLoadingState(false);
      }, 4000);
      // this.user = await Promise.resolve({ id: this.$route.params.id });
    }
  },
};
</script>

<style lang="scss" scoped>
.active-route {
  background-color: #45638a;
  color: white;
}
</style>